(function ($) {
    var defaultOptions = {
        highlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
				.addClass('has-error')
				.removeClass('has-success');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
			.removeClass('has-error')
			.addClass('has-success');
        },
        errorElement: 'span',
        errorClass: 'label label-danger',
        errorPlacement: function (error, element) {
            if (element.parent('.input-group').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        }
    };

    $.validator.addMethod("dateTimeITA", function (value, element) {
        console.log("Validating dateTimeITA");
        var check = false,
            re = /^\d{1,2}\/\d{1,2}\/\d{4} ([01]\d|2[0-3])(:[0-5]\d){1,2}$/,
            adata, gg, mm, aaaa, xdata;
        if (re.test(value)) {
            console.log("re.test passed");
            var dateTimeParts = value.split(" ");
            var datePart = dateTimeParts[0];
            var timePart = dateTimeParts[1];
            var timeParts = timePart.split(":");
            var hours = parseInt(timeParts[0], 10);
            var minutes = parseInt(timeParts[1], 10);

            adata = datePart.split("/");
            gg = parseInt(adata[0], 10);
            mm = parseInt(adata[1], 10);
            aaaa = parseInt(adata[2], 10);
            xdata = new Date(aaaa, mm - 1, gg, hours, minutes, 0, 0);
            console.log(xdata);
            if (xdata.getUTCFullYear() === aaaa && xdata.getUTCMonth() === mm - 1 && xdata.getUTCDate() === gg) {
                check = true;
            } else {
                check = false;
            }
        } else {
            check = false;
        }
        console.log("CHECK: " + check);
        console.log("RESULT: " + this.optional(element) || check);
        return this.optional(element) || check;
    }, "Please enter a correct date");

    $.validator.setDefaults(defaultOptions);

    $.validator.unobtrusive.options = {
        errorClass: 'has-error',
        validClass: 'has-success'
    };
})(jQuery);
